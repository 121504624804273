import type { HandleClientError } from '@sveltejs/kit';
import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_SENTRY_DSN, PUBLIC_ENVIRONMENT } from '$env/static/public';

Sentry.init({
	dsn: PUBLIC_SENTRY_DSN,
	release: 'beta',
	enabled: PUBLIC_ENVIRONMENT !== 'development',
	environment: PUBLIC_ENVIRONMENT,
});

export const handleError: HandleClientError = async ({ error, event, status, message }) => {
	const errorId = crypto.randomUUID();

	Sentry.captureException(error, {
		extra: {
			event,
			status,
			errorId,
			message,
		},
	});

	return {
		message: 'Whoops!',
		errorId,
	};
};
